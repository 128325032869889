import { getProductPage } from "@10xdev/cms/resolvers/get-product-page";
import { Locale } from "@10xdev/cms/types";
import { getPaths } from "@10xdev/cms/utils/get-paths";
import PageLayout from "@10xdev/ui/src/PageLayout";
import type { GetStaticPaths, GetStaticProps, NextPage } from "next";

interface Props {
  data: any;
  locale?: Locale;
}

const Product: NextPage<Props> = ({ data, locale }) => (
  <PageLayout data={data} locale={locale} />
);

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const paths = getPaths("product-pages", locales!);

  return {
    fallback: false,
    paths,
  };
};

export const getStaticProps: GetStaticProps = async ({ locale, params }) => {
  const data = await getProductPage(`${params!.slug}`, locale);

  return {
    props: {
      data: data,
      locale: locale,
    },
  };
};

export default Product;
